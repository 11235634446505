import React, {Component} from 'react';
import PropTypes from "prop-types";
import "./_table-input-cell.scss";
import Tooltip from "../../tooltip/Tooltip";
import {getIn} from "formik";

class TableInputCell extends Component {

    getText() {
        const {formatter, formatterProps} = this.props;
        if (formatter) return formatter(formatterProps);
        const {row, column: {key}} = formatterProps;
        let value = row[key];
        if (!(value instanceof Object)) return value;
        return null;
    }

    getError() {
        const {formatterProps: {column: {key}, rowIdx}, errors} = this.props;
        return getIn(errors, `[${rowIdx}].${key}`);
    }

    getClassName() {
        const {formatterProps: {column: {editable}, row}} = this.props;
        let className = "fetura-rdg-custom-cell";
        if (editable !== undefined) {
            if ((typeof editable === "function" && editable(row)) || (typeof editable === "boolean" && editable)) className += " editable";
        }
        if (this.getError()) className += " error";
        return className;
    }

    render() {
        let error = this.getError();
        return (
            <div className={this.getClassName()}>
                {this.getText()}
                {
                    error &&
                    <Tooltip tooltipContent={error} type={"error"}>
                        <i className="fas fa-exclamation-circle"/>
                    </Tooltip>
                }
            </div>
        );
    }
}

TableInputCell.propTypes = {
    formatter: PropTypes.func
}

export default TableInputCell;