import React from "react";
import PropTypes from "prop-types"
import TableHeader from "./TableHeader";
import "./_table.scss"
import TableRow from "./TableRow";
import Checkbox from "../checkbox/Checkbox";
import {myID} from "../../../libs/generateID";
import {findIndex, get, isEqual, isFunction, memoize} from "lodash";
import Button from "../button/Button";
import Input from "../input/Input";
import Hammer from "hammerjs";
import {isMobile} from "../../../utils/MobileUtils";
import MobileInfo from "../mobile-info/MobileInfo";
import {withCookies} from "react-cookie";
import moment from "moment";
import Filter from "./filter/Filter";
import sift, {createEqualsOperation} from "sift"
import animalsDB from "../../../database/animalsDB";
import {Level} from "../../../constans/levelTypes";
import {withTranslation} from "react-i18next";
import i18next from "i18next";
import {enhancedComparer} from "../../../utils/TextUtils";

export class TableGrid extends React.Component {

    constructor(props) {
        super(props);
        let cookie = this.getCookie();
        let maxPage = Math.ceil(props.data.length / cookie.paginationItems - 1) < 0 ? 0 : Math.ceil(props.data.length / cookie.paginationItems - 1);
        let headers = TableGrid.getFilteredHeaders(this.props.headers);
        this.state = {
            selectedRows: [],
            filterHeaders: props.headers.map(header => header.filterType ? header : undefined).filter(item => item),
            data: props.data.slice(0),
            paginatedData: props.data.slice(cookie.page, cookie.paginationItems),
            paginationItems: cookie.paginationItems,
            page: cookie.page > maxPage ? 0 : cookie.page,
            maxPage,
            sortColumn: cookie.sortColumn || this.props.initialSortColumn,
            sortType: this.props.initialSortColumn ? "asc" : cookie.sortType ? cookie.sortType : "off",
            expandedMobileNode: null,
            show: false,
            toFilter: this.createToFilterArray(props.data, headers),
            headers
        };
    }

    static getFilteredHeaders(headers) {
        return headers.map((header) => {
            let hasRole = true;
            const element = {
                ...header,
                valueFormatter: header.valueFormatter ? memoize(header.valueFormatter, (...args) => JSON.stringify(args)) : undefined
            };
            if (header.hasOwnProperty("shouldShow")) {
                hasRole = header.shouldShow();
            }
            if (hasRole) {
                return element;
            }
        }).filter(header => header);
    }

    componentDidMount() {
        try {
            if (this.props.showPagination && this.props.swipePagination) {
                this.hammer = new Hammer(this.ref);
                this.hammer.on("swiperight", () => this.swipePage(-1));
                this.hammer.on("swipeleft", () => this.swipePage(1));
            }
        } catch (e) {
            console.error(e);
        }
        this.onSortChange();
    }

    createToFilterArray(data, headers) {
        return data.map(row => {
            let obj = {_row: row};
            let toSearch = {};
            for (let header of headers) {
                let name = header.filterColumn || header.field;
                if (header.valueFormatter) {
                    try {
                        toSearch[name] = header.valueFormatter(get(row, header.field || header.filterColumn, row));
                    } catch (e) {
                        toSearch[name] = row[name];
                    }
                    if (!header.disableValueFormatterFilter) {
                        obj[name] = toSearch[name];
                    } else {
                        obj[name] = row[name];
                    }
                } else {
                    obj[name] = row[name];
                    toSearch[name] = row[name];
                }
            }
            obj._search = JSON.stringify(toSearch).toLowerCase();
            return obj;
        });
    }

    getCookie = () => {
        const {cookies, name, paginationItems, data} = this.props;
        let defaultCookie = {
            sortColumn: "",
            sortType: "off",
            page: 0,
            paginationItems: paginationItems || data.length,
            filters: []
        };
        if (name) {
            let cookie = cookies.get(name);
            try {
                return {
                    ...defaultCookie,
                    ...cookie
                }
            } catch (e) {
                return defaultCookie;
            }
        }
        return defaultCookie;
    }

    swipePage = (offset) => {
        try {
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.onPageChange(Math.max(Math.min(this.state.page + offset, this.state.maxPage), 0));
            }, 250)
        } catch (e) {
            console.error(e);
        }
    };

    componentWillUnmount() {
        for (let header of this.state.headers) {
            if (header.valueFormatter) {
                header.valueFormatter.cache.clear();
            }
        }
        clearTimeout(this.timer);
        try {
            this.hammer && this.hammer.destroy();
        } catch (e) {
            console.error(e);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
        if (!isEqual(this.props.headers, nextProps.headers)) {
            this.setState({
                headers: TableGrid.getFilteredHeaders(nextProps.headers)
            })
        }
        if (!isEqual(this.props.data, nextProps.data)) {
            let newObj = {
                data: nextProps.data.slice(0),
                page: this.props.data.length !== nextProps.data.length ? 0 : this.state.page,
                paginationItems: nextProps.showPagination ? this.state.paginationItems : nextProps.data.length,
                maxPage: Math.ceil(nextProps.data.length / this.state.paginationItems - 1) < 0 ? 0 : Math.ceil(nextProps.data.length / this.state.paginationItems - 1),
                toFilter: this.createToFilterArray(nextProps.data, this.state.headers)
            };
            if (this.props.clearSelectOnNewData) {
                newObj.selectedRows = [];
            }
            this.setState(state => (newObj), () => {
                this.setState({
                    paginatedData: this.paginateItems(nextProps.data)
                }, () => {
                    this.onSortChange();
                });
                if (this.props.clearSelectOnNewData) {
                    this.props.onSelectedRowsChanged([]);
                }
            })
        }
        if (!isEqual(this.props.selectedRows, nextProps.selectedRows)) {
            let selectedRows = nextProps.selectedRows.map(item => nextProps.data.indexOf(item));
            if (!isEqual(this.state.selectedRows, selectedRows)) {
                this.setState({
                    selectedRows
                })
            }
        }
    }

    onSelectAll = (value) => {
        const {data} = this.state;
        let selectedRows = [];
        if (value) {
            for (let object of data) {
                let index = findIndex(this.props.data, object);
                selectedRows.push(index);
            }
        }
        this.setState({
            selectedRows
        });
        this.changeIndexesToData(selectedRows);
    };

    onRowClick = (object) => {
        const {singleRowSelect, onRowClick, data} = this.props;
        let index = findIndex(data, object);
        if (onRowClick) onRowClick(object, index);
        let {selectedRows} = this.state;
        if (singleRowSelect) {
            selectedRows = [index];
        } else {
            if (selectedRows.includes(index)) selectedRows = selectedRows.filter(item => item !== index);
            else selectedRows.push(index);
        }
        this.setState({
            selectedRows,
        });
        this.changeIndexesToData(selectedRows);
    };

    changeIndexesToData(selectedRows) {
        const {data} = this.props;
        let array = selectedRows.map(value => data[value]);
        this.props.onSelectedRowsChanged(array);
    }

    paginateItems(data) {
        const {page, paginationItems} = this.state;
        return data.slice(page * paginationItems, page * paginationItems + paginationItems);
    }

    onFilterChange = filter => {
        console.log(filter);

        let filtered = this.state.toFilter.filter(sift(filter, {
            operations: {
                $devAdrEq(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => (!params.toString().startsWith("0x") ? `0x${parseInt(params).toString(16)}` : params) === value
                        , ownerQuery
                        , options
                    )

                },
                // StringAsNumberGreaterThanEquals
                $sanGte(params, ownerQuery, options) {
                    console.log(params, ownerQuery, options);
                    return createEqualsOperation(
                        value => {
                            console.log(value);
                            console.log(+((value + "").replace(/[^0-9.]/g, '')));
                            return +((value + "").replace(/[^0-9.]/g, '')) >= params
                        },
                        ownerQuery,
                        options
                    )
                },
                // StringAsNumberLessThanEquals
                $sanLte(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => +((value + "").replace(/[^0-9.]/g, '')) <= params,
                        ownerQuery,
                        options
                    )
                },
                // StringAsNumberEquals
                $sanEq(params, ownerQuery, options) {
                    console.log(params, ownerQuery, options);
                    return createEqualsOperation(
                        value => +((value + "").replace(/[^0-9.]/g, '')) === params,
                        ownerQuery,
                        options
                    )
                },
                // StringAsNumberNotEquals
                $sanNe(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => +((value + "").replace(/[^0-9.]/g, '')) !== params,
                        ownerQuery,
                        options
                    )
                },
                $includes(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => value ? value.toString().toUpperCase().includes(params.toString().toUpperCase()) : false,
                        ownerQuery,
                        options
                    )
                },
                $startsWith(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => value ? value.toString().toUpperCase().startsWith(params.toString().toUpperCase()) : false,
                        ownerQuery,
                        options
                    )
                },
                $endsWith(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => value ? value.toString().toUpperCase().endsWith(params.toString().toUpperCase()) : false,
                        ownerQuery,
                        options
                    )
                },
                $dateGte(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => {
                            let val = moment(value);
                            val.startOf("day");
                            return val.toDate().getTime() >= moment(params).toDate().getTime();
                        },
                        ownerQuery,
                        options
                    )
                },
                $dateLte(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => {
                            let val = moment(value);
                            val.startOf("day");
                            return val.toDate().getTime() <= moment(params).toDate().getTime();
                        },
                        ownerQuery,
                        options
                    )
                },
                $dateEq(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => {
                            let val = moment(value);
                            val.startOf("day");
                            return val.format("DD.MM.YYYY") === moment(params).format("DD.MM.YYYY");
                        },
                        ownerQuery,
                        options
                    )
                },
                $locLevel(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => {
                            if (value && params) {
                                let val = Array.isArray(value) ? value : [value];
                                return val.includes(params.object[params.key]);
                            }
                            return false;
                        },
                        ownerQuery,
                        options
                    )
                },
                $locBelow(params, ownerQuery, options) {
                    return createEqualsOperation(
                        value => {
                            if (value && params) {
                                let val = Array.isArray(value) ? value : [value];
                                let level = params.key === "BgID" ? Level.BUILDING : params.key === "SID" ? Level.SECTOR : params.key === "CID" ? Level.CHAMBER : Level.BOX;
                                let ids = animalsDB.scanLocalizationIds(params.object, level);
                                for (let id of ids) {
                                    if (val.includes(id)) return true;
                                }
                            }
                            return false;
                        },
                        ownerQuery,
                        options
                    )
                }
            }
        }));
        let maxPage = Math.ceil(filtered.length / this.state.paginationItems) - 1;
        let data = filtered.map(row => row._row);
        this.setState({
            data,
            paginatedData: this.paginateItems(data),
            selectedRows: [],
            maxPage,
            page: 0
        }, () => {
            this.changeIndexesToData([]);
            this.onSortChange();
        })
    };

    onPaginationAmountChange = value => {
        let maxPage = Math.ceil(this.state.data.length / value) - 1;
        let page = this.state.page > maxPage ? maxPage : this.state.page
        this.setState({
            paginationItems: +value,
            maxPage,
            page
        }, () => {
            const {name, cookies} = this.props;
            if (name) {
                let oldCookie = this.getCookie();
                let cookie = {
                    ...oldCookie,
                    page,
                    paginationItems: +value
                };
                cookies.set(name, cookie, {
                    path: "/",
                    expires: moment().add(1, "year").toDate()
                });
            }
            this.setState({
                paginatedData: this.paginateItems(this.state.data)
            })
        })
    };

    onPageChange = page => {
        this.setState({
            page
        }, () => {
            this.setState({
                paginatedData: this.paginateItems(this.state.data)
            }, () => {
                const {name, cookies, scrollOnPageChange} = this.props;
                if (name) {
                    let oldCookie = this.getCookie();
                    let cookie = {
                        ...oldCookie,
                        page
                    };
                    cookies.set(name, cookie, {
                        path: "/",
                        expires: moment().add(1, "year").toDate()
                    });
                }
                this.onSortChange();
                if(scrollOnPageChange){
                    this.ref.scrollIntoView({
                        behavior: "smooth"
                    });
                }

            })
        });
    };

    sortValues = (a, b, header={}) => {
        const {sortType} = this.state;
        const getValue = (_val) => {
            const val = header.field ? get(_val, header.field) : _val;
            if (header.valueFormatter && !header.disableValueFormatterSort) {
                return header.valueFormatter(val) + "";
            }
            return val;
        };
        return enhancedComparer(getValue(a), getValue(b), {numeric: true, ascending: sortType !== "desc", nonValuesAtEnd: false});
    };

    groupByFullWidthRows(data) {
        const {isFullWidthRow, fullWidthRowGroups} = this.props;
        if (fullWidthRowGroups) {
            let tmp = [];
            let array = [tmp];
            for (let d of data) {
                let isFullWidth = isFullWidthRow(d);
                if (isFullWidth) {
                    tmp = [d];
                    array.push(tmp);
                } else {
                    tmp.push(d);
                }
            }
            return array.filter(item => item.length > 0);
        } else {
            return [data];
        }
    }

    onSortChange = () => {
        const {sortColumn, sortType, data, headers} = this.state;
        const {fullWidthRowGroups} = this.props;
        let sorted = [];
        let header = headers.find(item => item.name === sortColumn);
        let clone = data.slice(0);
        let grouped = this.groupByFullWidthRows(clone);
        for (let group of grouped) {
            switch (sortType) {
                case "asc":
                    group.sort((a, b) => {
                        if (header && header.customSort) return header.customSort(a, b, header, sortType, get(a, header.field), get(b, header.field));
                        return this.sortValues(a, b, header);
                    });
                    break;
                case "desc":
                    group.sort((a, b) => {
                        if (header && header.customSort) return header.customSort(b, a, header, sortType, get(b, header.field), get(a, header.field));
                        return this.sortValues(a, b, header);
                    });
                    if (fullWidthRowGroups) {
                        group.unshift(group[group.length - 1]);
                        group = group.slice(0, group.length - 1);
                    }
                    break;
                default:
                    break;
            }
            sorted = [...sorted, ...group];
        }
        this.setState({
            paginatedData: this.paginateItems(sorted)
        })
    };

    onHeaderClick = (header) => {
        const {cookies, name} = this.props;
        let {sortType, sortColumn} = this.state;
        if (sortColumn === header.name) {
            switch (sortType) {
                case "off":
                    sortType = "asc";
                    break;
                case "asc":
                    sortType = "desc";
                    break;
                default:
                    sortType = "off";
            }
        } else {
            sortType = "asc";
        }
        this.setState({
            sortColumn: header.name,
            sortType
        }, () => {
            if (name) {
                let oldCookie = this.getCookie();
                let cookie = {
                    ...oldCookie,
                    sortColumn: header.name,
                    sortType
                };
                cookies.set(name, cookie, {
                    path: "/",
                    expires: moment().add(1, "year").toDate()
                });
            }
            this.onSortChange()
        });
    };

    createPinnedRows(data) {
        const {
            shouldIndex, selectableRow
        } = this.props;
        const {headers} = this.state;
        return data.map((row, i) => {
            if (React.isValidElement(row)) return (
                <div className="pinned-row">{row}</div>
            );
            return <TableRow object={row} headers={headers} key={i} isPinned
                             isSelectable={selectableRow} shouldIndex={shouldIndex}
            />
        })
    }

    onSelectMobileNode = (node) => {
        this.setState({
            show: true,
            expandedMobileNode: node
        })
    };

    onHide = () => {
        this.setState({
            show: false
        }, () => {
            setTimeout(() => {
                this.setState({
                    expandedMobileNode: null
                })
            }, 300)
        })
    };

    getMobileInfoName() {
        const {mobileAdditionalInfoName} = this.props;
        const {expandedMobileNode} = this.state;
        if (typeof mobileAdditionalInfoName === "function") return mobileAdditionalInfoName(expandedMobileNode);
        return mobileAdditionalInfoName;
    }

    checkIfSelected(i) {
        const {selectedRows, paginatedData} = this.state;
        const {data} = this.props;
        let paginatedRow = paginatedData[i];
        let index = findIndex(data, paginatedRow);
        return selectedRows.includes(index);
    }

    getBeforeFullWidthAmount(index) {
        const {isFullWidthRow} = this.props;
        if (isFullWidthRow) {
            const {data} = this.state;
            let amount = 0;
            for (let i = 0; i < index; i++) {
                if (data[i] && isFullWidthRow(data[i])) {
                    amount++;
                }
            }
            return amount;
        }
        return 0;
    }

    onQuickFilterChange = value => {
        const {toFilter} = this.state;
        let filtered = toFilter.filter(item => item._search.includes(value.toLowerCase()));
        let maxPage = Math.ceil(filtered.length / this.state.paginationItems) - 1;
        let data = filtered.map(row => row._row);
        this.setState({
            data,
            paginatedData: this.paginateItems(data),
            selectedRows: [],
            maxPage,
            page: 0
        }, () => {
            this.changeIndexesToData([]);
            this.onSortChange();
        })
    };

    render() {
        const {
            title, shouldIndex, selectableRow, showPagination, className, bottomPinnedRows,
            topPinnedRows, isFullWidthRow, fullWidthRow, isSortable, onRowClick, t, singleRowSelect,
            mobileRow, mobileAdditionalInfo, rowClassName, isExpanded, component, smallPagination
        } = this.props;
        const {
            selectedRows, paginationItems, page, paginatedData, maxPage, data, sortColumn,
            sortType, show, expandedMobileNode, filterHeaders, headers
        } = this.state;
        const mobile = isMobile();
        const classArray = ["fetura-grid", className];
        mobile && (classArray.push("mobile"));
        const classNames = classArray.filter(o => o).join(" ");
        return (
            <React.Fragment>
                <Filter {...this.props} filterHeaders={filterHeaders} onFilterChange={this.onFilterChange}
                        onQuickFilterChange={this.onQuickFilterChange}/>
                {
                    title && <h4>{title}</h4>
                }
                <div className={classNames} ref={ref => this.ref = ref}>
                    {
                        !(mobile && mobileRow) &&
                        <div className="header-container">
                            {
                                selectableRow && !singleRowSelect &&
                                <div className="header-item selected-header">
                                    <Checkbox label="" id={myID()} onChange={this.onSelectAll}
                                              checked={selectedRows.length === data.length}/>
                                </div>
                            }
                            {
                                shouldIndex && <div className="header-item index">#</div>
                            }
                            {
                                headers.map((header, i) => <TableHeader key={i} header={header}
                                                                        isSortable={isSortable}
                                                                        onSortChange={this.onHeaderClick}
                                                                        sortColumn={sortColumn}
                                                                        sortType={sortType}/>)
                            }
                        </div>
                    }
                    {
                        !!topPinnedRows &&
                        <div className="top-pinned-container">
                            {
                                isFunction(topPinnedRows)
                                    ? this.createPinnedRows(topPinnedRows(paginatedData, paginationItems, data))
                                    : this.createPinnedRows(topPinnedRows)
                            }
                        </div>
                    }
                    <div className="body-container">
                        {
                            paginatedData.map((object, i) => {
                                let isFullWidth = isFullWidthRow && isFullWidthRow(object);
                                if (isMobile() && mobileRow) return (
                                    isFullWidth && fullWidthRow ?
                                        React.cloneElement(fullWidthRow, {
                                            object, //TODO dodac wiecej propsow
                                        }, fullWidthRow.props.children)
                                        : React.cloneElement(mobileRow, {
                                            ...mobileRow.props,
                                            object,
                                            headers,
                                            paginationItems,
                                            page,
                                            key: (page * paginationItems) + i,
                                            index: (page * paginationItems) + i + 1,
                                            onClick: this.onSelectMobileNode,
                                            onSelect: this.onRowClick,
                                            selected: this.checkIfSelected(i),
                                            className: rowClassName,
                                            singleRowSelect: singleRowSelect,
                                            isSelectable: selectableRow

                                        }
                                        )
                                );
                                return (
                                    <TableRow headers={headers} object={object}
                                              key={(page * paginationItems) + i}
                                              index={(page * paginationItems) + i + 1}
                                              singleRowSelect={singleRowSelect}
                                              shouldIndex={shouldIndex} isSelectable={selectableRow}
                                              selected={this.checkIfSelected(i)}
                                              mobile={mobile}
                                              onClick={this.onRowClick} page={page}
                                              paginationItems={paginationItems} isFullWidthRow={isFullWidthRow}
                                              fullWidthRow={fullWidthRow}
                                              isClickable={selectableRow || !!onRowClick}
                                              className={rowClassName}
                                              fullWidthBefore={this.getBeforeFullWidthAmount((page * paginationItems) + i)}
                                              isExpanded={isExpanded}
                                              component={component}
                                    />
                                )
                            })
                        }
                        {
                            paginatedData.length === 0 &&
                            <div className="empty-list">
                                <i>{t("tableGrid.noData")}</i>
                            </div>
                        }
                    </div>
                    {
                        !!bottomPinnedRows &&
                        <div className="bottom-pinned-container">
                            {
                                isFunction(bottomPinnedRows)
                                    ? this.createPinnedRows(bottomPinnedRows(paginatedData, paginationItems, data))
                                    : this.createPinnedRows(bottomPinnedRows)
                            }
                        </div>
                    }
                    {
                        showPagination && !smallPagination &&
                        <div className="pagination">
                            <div className="items-amount">
                                {i18next.t("tableGrid.displaying", {type1: paginatedData.length, type2: data.length})}
                            </div>
                            <div className="pagination-buttons">
                                <Button buttonStyle={"round"} icon={<i className="fas fa-step-backward"/>}
                                        onClick={() => this.onPageChange(0)}/>
                                <Button buttonStyle={"round"} icon={<i className="fas fa-backward"/>}
                                        onClick={() => this.onPageChange(page - 1)} disabled={page === 0}/>
                                <span>{page + 1}/{maxPage + 1}</span>
                                <Button buttonStyle={"round"} icon={<i className="fas fa-forward"/>}
                                        onClick={() => this.onPageChange(page + 1)} disabled={page === maxPage}/>
                                <Button buttonStyle={"round"} icon={<i className="fas fa-step-forward"/>}
                                        onClick={() => this.onPageChange(maxPage)}/>
                                <Input type="number" value={paginationItems}
                                       onChange={this.onPaginationAmountChange}/>
                            </div>
                        </div>
                    }
                    {
                        showPagination && smallPagination &&
                        <div className="pagination small-pagination">
                            <Button buttonStyle={"round"} icon={<i className="fas fa-backward"/>}
                                    onClick={() => this.onPageChange(page - 1)} disabled={page === 0}/>
                            <Button buttonStyle={"round"} icon={<i className="fas fa-forward"/>}
                                    onClick={() => this.onPageChange(page + 1)} disabled={page === maxPage}/>
                        </div>
                    }
                </div>
                {
                    isMobile() && mobileAdditionalInfo &&
                    <MobileInfo show={show} name={this.getMobileInfoName()} onHide={this.onHide}>
                        {
                            React.cloneElement(mobileAdditionalInfo, {
                                ...mobileAdditionalInfo.props,
                                object: expandedMobileNode
                            })
                        }
                    </MobileInfo>
                }
            </React.Fragment>
        );
    }

}

TableGrid.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        headerComponent: PropTypes.element,
        customSort: PropTypes.func, //funkcja, ktora sortuje dane, (obiekt1, obiekt2, header, typ sortowania, formattedVal1, formattedVal2)
        disableValueFormatterSort: PropTypes.bool,
        disableValueFormatterFilter: PropTypes.bool,
        notSortable: PropTypes.bool,
        //propsy do defaultowego widoku mobilnego
        _floating: PropTypes.bool, //tutaj wrzucasz jak masz jakis ikony z akcjami (najlepiej jakby byly customowe komponenty z <i className itd..>
        _hideMobile: PropTypes.bool, //tutaj dajesz true jesli nie chcesz zeby wyswietlalo sie na mobilkach (najlepiej jak masz jakies dziwne komorki ktore sie slabo wyswietaja)
        _mobileDate: PropTypes.bool, //tutaj jesli masz date jakas glowna to oznacz to sie wyswietli w kaflu po prawej gornej stronie (wartosc timestamp)
        _mobileHeader: PropTypes.bool, //tutaj daj najwazniejsza kolumne albo nic wtedy na kaflu robi sie ona troche wieksza
    })).isRequired,
    data: PropTypes.array.isRequired,
    shouldIndex: PropTypes.bool,
    onSelectedRowsChanged: PropTypes.func,
    selectableRow: PropTypes.bool,
    showFilter: PropTypes.bool,
    showPagination: PropTypes.bool,
    swipePagination: PropTypes.bool,
    paginationItems: PropTypes.number,
    className: PropTypes.string,
    bottomPinnedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    topPinnedRows: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
    isFullWidthRow: PropTypes.func,
    fullWidthRow: PropTypes.node,
    isSortable: PropTypes.bool,
    saveToExcel: PropTypes.bool,
    excelFileName: PropTypes.string,
    onRowClick: PropTypes.func,
    singleRowSelect: PropTypes.bool,
    title: PropTypes.string,
    clearSelectOnNewData: PropTypes.bool,
    mobileRow: PropTypes.node,
    mobileAdditionalInfo: PropTypes.node,
    mobileAdditionalInfoName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    selectedRows: PropTypes.array,
    rowClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    name: PropTypes.string, // nazwa grida do zapisu w cookiesach danych
    fullWidthRowGroups: PropTypes.bool,
    isExpanded: PropTypes.bool,
    contentValue: PropTypes.node,
    component: PropTypes.node,
    scrollOnPageChange: PropTypes.bool,
    initialSortColumn: PropTypes.string,
    smallPagination: PropTypes.bool,
};

TableGrid.defaultProps = {
    shouldIndex: false,
    onSelectedRowsChanged: () => {
    },
    selectableRow: false,
    className: "",
    excelFileName: "noName",
    singleRowSelect: false,
    swipePagination: false,
    title: "",
    clearSelectOnNewData: true,
    isSortable: true,
    isExpanded: false,
    scrollOnPageChange: true,
    initialSortColumn: null
};

TableGrid = withCookies(TableGrid);


TableGrid = withTranslation()(TableGrid);

export default TableGrid;
