import React, {Component} from 'react';
import DataGrid, {UpdateActions} from "react-data-grid";
import PropTypes from "prop-types";
import 'react-data-grid/dist/react-data-grid.css';
import {isEmpty, isEqual, debounce} from "lodash";
import {AutoSizer} from "react-virtualized";
import "./_table-input.scss"
import TableInputCell from "./cell/TableInputCell";
import {ROW_HEIGHT} from "../../../utils/DataGridUtils";

class TableInput extends Component {

    state = {
        columns: TableInput.getColumns(this.props),
        value: this.props.value || [],
        filters: this.props.initialFilters,
        enableCellDragAndDrop: true
    };

    container = React.createRef();
    grid = React.createRef();

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            columns: TableInput.getColumns(nextProps),
            value: nextProps.value || []
        }
    }

    static getColumns(props) {
        const {shouldIndex, columns} = props;
        let cols = columns.map(col => ({
            ...col,
            editable: col.editable !== undefined ? col.editable : true,
            formatter: (p) => <TableInputCell formatterProps={p} formatter={col.formatter} errors={props.errors}/>
        }));
        if (shouldIndex) {
            cols.unshift({
                name: "#",
                key: "index",
                width: 10,
                formatter: ({rowIdx}) => rowIdx + 1,
                frozen: true
            })
        }
        return cols;
    }

    componentDidMount() {
        const {shouldIndex} = this.props;
        const {columns} = this.state;
        // jezeli jest indeksowanie to zaznacz druga kolumne, pierwszy wiersz
        if (shouldIndex) {
            // sprawdzanie czy ma wylaczyc drag and drop na starcie apki
            if (columns[1].disableDragAndDrop) {
                this.setState({
                    enableCellDragAndDrop: false
                })
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !isEqual(this.state, nextState);
    }

    getColumn(key) {
        return this.state.columns.find(item => item.key === key);
    }

    /**
     * Metoda, która automatycznie uzupełnia wartości na podstawie wybranych filtrów
     * @param currentValue
     * @param updated
     * @param cellKey
     */
    autoInsertValues(currentValue, updated, cellKey) {
        const {filters} = this.state;
        let obj = {...currentValue, ...updated};
        for (let key in filters) {
            let column = this.getColumn(key);
            if (!column.disableAutoInsert && cellKey !== key && (column.insertEvenIfNotEditable || (typeof column.editable === "function" && column.editable(obj)) || (typeof column.editable === "boolean" && column.editable))) {
                let filter = filters[key];
                if (!obj[key]) {
                    obj[key] = filter;
                    obj = {
                        ...obj,
                        ...(column.onChange ? column.onChange(obj, obj, this.state.filters) : {})
                    }
                }
            }
        }
        return obj;
    }

    handleRowUpdate = ({action, fromRow, toRow, updated, cellKey, fromCellKey}) => {
        let value = this.state.value.slice(0);
        let start;
        let end;
        if (action === UpdateActions.COPY_PASTE) {
            if (this.props.disableCopyPasteBetweenColumns && cellKey !== fromCellKey) {
                return;
            }
            start = toRow;
            end = toRow;
        } else {
            start = Math.min(fromRow, toRow);
            end = Math.max(fromRow, toRow);
        }
        let disableOnRows = this.props.getDisabledRows ? this.props.getDisabledRows(value, start, end) : [];

        let column = this.state.columns.find(item => item.key === cellKey);
        if (column) {
            // czyszczenie wiersza jezeli kolumna ma clearRowOnRemove
            if (column.clearRowOnRemove && updated[cellKey] === null) {
                for (let col of this.state.columns) {
                    if (col.key !== "index" && !col.noClearing) {
                        updated[col.key] = null;
                    }
                }
            }
        }

        for (let key in updated) {
            if (updated[key] === undefined) {
                delete updated[key];
            }
        }
        if (!isEmpty(updated)) {
            for (let i = start; i <= end; i++) {
                if (disableOnRows.includes(i)) continue;
                // onChange na danej kolumnie
                if (column) {
                    if (column.onChange) {
                        updated = column.onChange(updated, value[i], this.state.filters);
                    }
                }
                if (!updated[column.key]) {
                    value[i] = {...value[i], ...updated};
                } else {
                    if (!(column.clearRowOnRemove && !updated[column.key]) && this.props.enableAutoInsert) {
                        value[i] = this.autoInsertValues(value[i], updated, cellKey);
                    } else {
                        value[i] = {...value[i], ...updated};
                    }
                }
            }
            // setFieldValue("data", value);
            if (this.props.onDataChange) this.props.onDataChange(value, this.state.filters);
            this.props.onChange(this.props.name, value);
        }
    }

    addRow = debounce(() => {
        const value = this.state.value.slice(0);
        value.push({});
        if (this.props.onDataChange) this.props.onDataChange(value, this.state.filters);
        this.props.onChange(this.props.name, value);
    }, 25);

    onSelectedCellChange = ({idx, rowIdx}) => {
        const {shouldIndex, disableAddingNewRow} = this.props;
        const {columns, enableCellDragAndDrop} = this.state;
        const value = this.state.value.slice(0);
        // wyłącz zaznaczanie pierwszej kolumny jeżeli jest włączone indeksowanie
        if (shouldIndex && idx === 0) {
            this.grid.current.selectCell({rowIdx, idx: 1});
        }
        // jezeli zaznaczono kolumne z disableDragAndDrop lub kolumne indeksu to wylacz drag and drop
        if (columns[idx].disableDragAndDrop || (shouldIndex && idx === 0)) {
            if (enableCellDragAndDrop) {
                this.setState({
                    enableCellDragAndDrop: false
                })
            }
        } else {
            if (!enableCellDragAndDrop) {
                this.setState({
                    enableCellDragAndDrop: true
                })
            }
        }
        // dodanie kolejnego wiersza ponieważ zaznaczono ostatni
        if (!disableAddingNewRow) {
            if (rowIdx === value.length - 1) {
                this.addRow();
            }
        }
    }

    onFiltersChange = filters => {
        const {onFiltersChange} = this.props;
        let value = this.state.value.slice(0);
        if (onFiltersChange) {
            const nonOccupiedRows = Array.isArray(value) && value.filter((item) => !item.occupied);
            const occupiedRows = Array.isArray(value) && value.filter((item) => item.occupied);
            value = onFiltersChange(filters, nonOccupiedRows, this.state.filters);
            const finalRows = [...occupiedRows, ...value];
            if (this.props.onDataChange) this.props.onDataChange(finalRows, filters);
            this.props.onChange(this.props.name, finalRows);
        }
        this.setState({
            filters
        })
    }

    clearFilters() {
        this.setState({
            filters: {}
        })
    }

    removeFilter(field) {
        const {filters} = this.state;
        delete filters[field];
        this.onFiltersChange(filters);
    }

    setFilters(filters) {
        this.setState({
            filters
        })
    }

    render() {
        const {columns, value, filters, enableCellDragAndDrop} = this.state;
        return (
            <div className="fetura-table-input" ref={this.container}>
                <AutoSizer>
                    {
                        ({width, height}) => (
                            <DataGrid columns={columns}
                                      rows={value}
                                      onRowsUpdate={this.handleRowUpdate}
                                      onSelectedCellChange={this.onSelectedCellChange}
                                      ref={this.grid}
                                      enableFilters
                                      onFiltersChange={this.onFiltersChange}
                                      filters={filters}
                                      enableCellDragAndDrop={enableCellDragAndDrop}
                                      enableCellCopyPaste
                                      rowHeight={ROW_HEIGHT}
                                      style={{width, height}}
                            />
                        )
                    }
                </AutoSizer>
            </div>
        );
    }
}

TableInput.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired, // nazwa kolumny
        key: PropTypes.string.isRequired, // klucz kolumny
        editor: PropTypes.object, // edytor, zazwyczaj React.forwardRef((props, ref) => <Component {...props} ref={ref} {..additionalProps}/>)
        formatter: PropTypes.func, // formatter
        disableAutoInsert: PropTypes.bool, // czy ma wylaczyc automatyczne wypelnianie wartosci
        disableDragAndDrop: PropTypes.bool, // wyłącza drag and drop na tej kolumnie
        clearRowOnRemove: PropTypes.bool, // czy ma czyscic wiersz na usunieciu danych
        insertEvenIfNotEditable: PropTypes.bool, // czy ma wstawiac wartosc nawet jak jest wylaczony
        noClearing: PropTypes.bool, // zapobiega usuwaniu
    })).isRequired,
    shouldIndex: PropTypes.bool, // czy ma indeksowac wiersze
    onFiltersChange: PropTypes.func, // funkcja wykonywana na zmianie wiersza z filtrami
    enableAutoInsert: PropTypes.bool, // czy włączone jest automatyczne uzupełnianie wartości
    initialFilters: PropTypes.object, // pierwsze filtry, które ma ustawić
    disableCopyPasteBetweenColumns: PropTypes.bool,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    name: PropTypes.string,
    disableAddingNewRow: PropTypes.bool,
    onDataChange: PropTypes.func,
    getDisabledRows: PropTypes.func, // metoda, która wykonuje się na kopiowaniu, która blokuje dane wiersze
}

TableInput.defaultProps = {
    enableAutoInsert: true,
    initialFilters: {},
    disableCopyPasteBetweenColumns: true,
    name: "data"
}

export default TableInput;
