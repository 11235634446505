import React from "react";
import PropTypes from "prop-types"
import "./_stepper-mobile.scss"
import CircularProgressBar from "../circular-progressbar/CircularProgressBar";

export default class StepperMobile extends React.Component {

    render() {
        const {children, activeStep} = this.props;
        const index = Math.max(activeStep, 1) - 1;
        const before = index ? children[index - 1] : null;
        const current = children[index];
        const after = (index + 1) < children.length ? children[index + 1] : null;
        const percentage = Math.round(100*activeStep/children.length);
        return (
            <div className="stepper-mobile">
                <div className={"step-list"}>
                    <div className={"step-before"}>{before}</div>
                    <div className={"step-current"}>{current}</div>
                    <div className={"step-after"}>{after}</div>
                </div>
                <div className={"step-progress"}><CircularProgressBar monoColor={true} color={percentage >= 100 ? "success" : "info"} text={`${activeStep}/${children.length}`} percentage={percentage}/></div>
            </div>
        );
    }

}

StepperMobile.propTypes = {
    activeStep: PropTypes.number,
    onClickStep: PropTypes.func
};

StepperMobile.defaultProps = {
    onClickStep: () => {
    }
};
