import React from "react";
import PropTypes from "prop-types";
import {myID} from "../../../libs/generateID";
import Switch from "./Switch";

export default class ReduxSwitch extends React.Component {

    onChange = () => {
        this.props.input.onChange(!!event.target.checked);
    };

    render() {
        const {label, id, input: {value}, disabled, reverseOrder} = this.props;
        return (
            <Switch reverseOrder={reverseOrder} id={id} label={label} value={!!value} onChange={this.onChange}
                    disabled={disabled}/>
        );
    }

}

ReduxSwitch.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    reverseOrder: PropTypes.bool
};

ReduxSwitch.defaultProps = {
    id: myID(),
    reverse: false
};
