import React from "react";
import PropTypes from "prop-types"
import "./_tab.scss"

export default class TabContainer extends React.Component {

    getChildren() {
        const {children} = this.props;
        if (Array.isArray(children)) return children;
        return [children];
    }

    onChangeIndex = (index) => {
        this.props.onSlide(index);
    };

    render() {
        const {activeKey} = this.props;
        //todo: fix scroll staying after scroll
        //todo: fix overflow and flickering
        let children = this.getChildren();
        return (
            <div className="fetura-tabs">
                {
                    children.filter(o => o).map((child, index) => activeKey === index ? React.cloneElement(child, {
                        isActive: activeKey === index,
                        key: index
                    }) : <div key={index}/>)
                }
            </div>
        )
    }
}

TabContainer.propTypes = {
    activeKey: PropTypes.node.isRequired,
    onSlide: PropTypes.func.isRequired
};