import React, {Component} from 'react';
import Button from "../../../button/Button";
import PropTypes from 'prop-types';
import {withTranslation} from "react-i18next";

class FilterOr extends Component {

    container = React.createRef();
    line = React.createRef();

    componentDidMount() {
        this.getHeightOfLine();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.getHeightOfLine();
    }

    getOffsetTop(element) {
        let offsetTop = 0;
        while(element !== this.container.current) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    getHeightOfLine() {
        if (this.container.current) {
            let children = this.container.current.querySelectorAll(":scope > .filter-level");
            let firstElement = children[0];
            let lastElement = children[children.length - 1];
            let top = 0;
            if (firstElement.classList.contains("or")) {
                let line = firstElement.querySelector(":scope > .line");
                top = line.clientHeight / 2 + +line.style.top.replace("px", '');
            } else {
                let firstFilterData = firstElement.querySelector(".filter-data");
                top = firstFilterData.clientHeight / 2;
            }
            this.line.current.style.top = `${top}px`;
            if (lastElement.classList.contains("or")) {
                let label = lastElement.querySelector(":scope > .line > .line-label");
                let height = this.getOffsetTop(label);
                this.line.current.style.height = `${height - top}px`;
            } else {
                let lastFilterData = lastElement.querySelector(".filter-data");
                let offset = this.getOffsetTop(lastFilterData);
                this.line.current.style.height = `${offset + (lastFilterData.clientHeight / 2) - top}px`;
            }
        }
    }

    onAddOrClick = () => {
        const {value, path, onFilterChange} = this.props;
        value.push({$and: [{}]});
        onFilterChange(value, path);
    };

    render() {
        const {children, t} = this.props;
        return (
            <div className="filter-level or" ref={this.container}>
                {children}
                <div className="line" ref={this.line}>
                    <div className="line-label">{t("filterGrid.or")}</div>
                </div>
                <div className="filter-level-controls">
                    <Button onClick={this.onAddOrClick}>Lub</Button>
                </div>
            </div>
        );
    }
}

FilterOr.propTypes = {
    value: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
    onFilterChange: PropTypes.func.isRequired,
};

export default withTranslation()(FilterOr);