import React from "react";
import PropTypes from "prop-types";
// import Fade from "react-bootstrap/es/Fade";
import "./_tab.scss"

export default class Tab extends React.Component {

    render() {
        const {children, isActive} = this.props;
        return (
            <div className="tab" style={isActive ? {} : {display: "none"}}>
                {React.cloneElement(children, {...children.props, isActive: isActive})}
            </div>
        );
    }

}

Tab.propTypes = {
    activeKey: PropTypes.node
};