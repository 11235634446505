import React from "react";
import PropTypes from "prop-types"
import {myID} from "../../../libs/generateID";
import "./_switch.scss"

export default class Switch extends React.Component {

    onChange = e => {
        this.props.onChange(!!e.target.checked, e)
    };

    onDivClick = e => {
        if (!this.props.disabled) {
            this.props.onDivClick && this.props.onDivClick(e);
        }
    };

    render() {
        const {label, id, disabled, value, reverseOrder, additionalClass, title} = this.props;
        return (
            <div title={title} className={`fetura-switch ${additionalClass}`} onClick={this.onDivClick}>
                <input disabled={disabled} checked={value} type="checkbox" id={id} onChange={this.onChange}/>
                <label className={reverseOrder ? "reverse" : undefined} htmlFor={id}><span className={"switch"}/><span className={"no-break-word"}>{label}</span>
                </label>
            </div>
        );
    }

}

Switch.propTypes = {
    label: PropTypes.string.isRequired,
    additionalClass: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.bool,
    id: PropTypes.string,
    disabled: PropTypes.bool,
    onDivClick: PropTypes.func,
    reverseOrder: PropTypes.bool
};

Switch.defaultProps = {
    onChange: (value) => {
    },
    id: myID(),
    disabled: false,
    reverseOrder: false
};
