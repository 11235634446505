import React, {Component} from 'react';
import {connect} from 'react-redux';
import "./_filter.scss";
import Button from "../../button/Button";
import Input from "../../input/Input";
import FilterLevel from "./filter-level/FilterLevel";
import _ from "lodash"
import PropTypes from "prop-types";
import FilterRow from "./filter-level/FilterRow";
import FilterOr from "./filter-level/FilterOr";
import XLSX from "../../../../beans/XLSX/XLSX";
import {withTranslation} from "react-i18next";

function mapStateToProps(state) {
    return {
        farm: state.location.farm,
    };
}

class Filter extends Component {

    state = {
        showFilter: false,
        filter: {
            $and: [{}]
        },
        quickFilter: ""
    };

    constructor(props) {
        super(props);
        this.quickFilter = _.debounce(this.props.onQuickFilterChange, 300);
    }

    onFilterButtonClick = () => {
        this.setState(state => ({
            showFilter: !state.showFilter,
            quickFilter: ""
        }));
        if (this.state.quickFilter !== "") {
            this.props.onQuickFilterChange("");
        }
    };

    onTypeChange = (type, path) => {
        let {filter} = this.state;
        let value = _.get(filter, path);
        let split = path.split(".");
        let pathWithoutLast = split.slice(0, -1).join(".");
        let lastKey = split[split.length - 1];
        let level = _.get(filter, pathWithoutLast, filter);
        delete level[lastKey];
        level[type] = value;
        if (pathWithoutLast) {
            _.set(filter, pathWithoutLast, level);
        } else {
            filter = level;
        }
        this.setState({
            filter
        })
    };

    onChangeFilters = (value, path) => {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        _.set(filter, path, value);
        this.setState({
            filter
        })
    };

    isNewGroup(value) {
        let keys = Object.keys(value);
        return keys.includes("$and");
    }

    clearParent(filter, path) {
        let parent = _.get(filter, path, filter);
        let keys = Object.keys(parent)
        if (keys.length === 0) {
            _.unset(filter, path);
        } else {
            let key = keys[0];
            parent[key] = parent[key].filter(item => item);
            if (parent[key].length === 1) {
                if (path) {
                    _.set(filter, path, parent[key][0]);
                } else {
                    filter = parent[key][0];
                }
            }
        }
        let parentPath = path.split(".").slice(0, -1).join("");
        if (path) {
            filter = this.clearParent(filter, parentPath);
        }
        return filter;
    }

    onRemove = (path, index) => {
        let filter = JSON.parse(JSON.stringify(this.state.filter));
        let pathed = _.get(filter, path, []);
        pathed.splice(index, 1);
        if (pathed.length === 0) {
            _.unset(filter, path);
            let parentPath = path.split(".").slice(0, -1).join(".");
            filter = this.clearParent(filter, parentPath);
        }
        if (_.isEmpty(filter)) {
            filter = {
                $and: [{}]
            }
        }
        this.setState({
            filter
        })
    };

    onOrClick = path => {
        let {filter} = this.state;
        let split = path.split(".");
        let pathWithoutLast = split.slice(0, -1).join(".");
        let level = _.get(filter, pathWithoutLast, filter);
        let or = {$or: [level, {$and: [{}]}]};
        if (pathWithoutLast) {
            _.set(filter, pathWithoutLast, or);
        } else {
            filter = or;
        }
        this.setState({
            filter
        });
    };

    renderFilters(filter = this.state.filter, path = "") {
        let component;
        for (let key in filter) {
            let value = filter[key];
            path += path ? "." + key : key;
            if (key === "$or") {
                component = (
                    <FilterOr value={value} path={path} onFilterChange={this.onChangeFilters}>
                        {
                            value.map((val, index) => this.renderFilters(val, path + `[${index}]`))
                        }
                    </FilterOr>
                )
            } else {
                component = (
                    <FilterLevel value={{[key]: value}} onChangeFilters={this.onChangeFilters} path={path}
                                 onTypeChange={this.onTypeChange} onOrClick={this.onOrClick}>
                        {
                            value.map((val, index) => {
                                if (this.isNewGroup(val)) return this.renderFilters(val, path + `[${index}]`);
                                return <FilterRow index={index} path={path} onRemove={this.onRemove}
                                                  headers={this.props.headers} value={val}
                                                  onFilterChange={this.onChangeFilters} farm={this.props.farm}
                                />
                            })
                        }
                    </FilterLevel>
                )
            }
        }
        return component;
    }

    onApplyFilterClick = () => {
        const {onFilterChange} = this.props;
        onFilterChange(this.state.filter);
    };

    onQuickFilterChange = value => {
        this.setState({
            quickFilter: value
        });
        this.quickFilter(value);
    };

    onSaveToExcelClick = () => {
        const {headers, data, excelFileName} = this.props;
        let d = XLSX.formatData(headers, data);
        let xlsx = new XLSX(d, {
            headers
        });
        xlsx.save(excelFileName);
    };

    onClearClick = () => {
        let filter = {
            $and: [{}]
        };
        this.setState({
            filter
        });
        this.props.onFilterChange(filter);
    };

    render() {
        if (!this.props.showFilter) return null;
        const {showFilter, quickFilter} = this.state;
        const {t, saveToExcel} = this.props;
        return (
            <div className="table-grid-filter">
                <div className="table-grid-filter-buttons">
                    <div className="table-grid-filter-buttons-main">
                        <Button buttonStyle={"round"} icon={<i className="fas fa-filter"/>}
                                onClick={this.onFilterButtonClick}/>
                        <Input type={"text"} placeholder={t("filterGrid.filter")} onChange={this.onQuickFilterChange}
                               value={quickFilter} disabled={showFilter}/>
                    </div>
                    {
                        saveToExcel &&
                        <Button buttonStyle={"round"} buttonColor={"success"} icon={<i className="fas fa-file-excel"/>}
                                onClick={this.onSaveToExcelClick}/>
                    }
                </div>
                {
                    showFilter &&
                    <div className="table-grid-filter-advanced">
                        {this.renderFilters()}
                        <div className="table-grid-filter-advanced-apply">
                            <Button icon={<i className="fas fa-eraser"/>} onClick={this.onClearClick}>
                                {t("clear")}
                            </Button>
                            <Button buttonColor={"success"} icon={<i className="fas fa-check"/>}
                                    onClick={this.onApplyFilterClick}>
                                {t("filterGrid.filter")}
                            </Button>
                        </div>
                    </div>
                }
            </div>
        );
    }

}

Filter.propTypes = {
    onFilterChange: PropTypes.func.isRequired,
    onQuickFilterChange: PropTypes.func.isRequired
};


Filter = connect(
    mapStateToProps,
)(Filter);

export default withTranslation()(Filter)