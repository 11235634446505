import React from "react";
import PropTypes from "prop-types"
import "./_step.scss"

export default class Step extends React.Component {

    getClassName = () => {
        const {isActive, isFinished, mobile, disabled} = this.props;
        let className = mobile ? "step-mobile" : "step";
        if (isActive) className += " active";
        if (isFinished) className += " active finished";
        if (disabled) className += " disabled";
        return className;
    };

    onClick = () => {
        const {isFinished, index} = this.props;
        if (isFinished) {
            this.props.onClick(index)
        }
    };

    render() {
        const {isFinished, index, label, showLine, mobile, disabled} = this.props;
        return (
            <div className={this.getClassName()} onClick={this.onClick}>
                <div className="step-circle">
                    {
                        isFinished && <i className="fas fa-check"/>
                    }
                    {
                        !isFinished && index
                    }
                </div>
                <div className="step-label">
                        {label}
                </div>
                {
                    showLine && !mobile && <span className="step-line"/>
                }
                {
                    disabled && <i className="fas fa-times"/>
                }
            </div>
        );
    }

}

Step.propTypes = {
    label: PropTypes.node.isRequired,
    index: PropTypes.number,
    isActive: PropTypes.bool,
    isFinished: PropTypes.bool,
    showLine: PropTypes.bool,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Step.defaultProps = {
    onClick: () => {
    }
};

