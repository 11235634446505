import React, {Component} from 'react';
import TreeSelect from "../../tree-select/TreeSelect";
import buildingsDB from "../../../../database/buildingsDB";
import PropTypes from "prop-types"

class LocationEditor extends Component {

    state = {
        value: this.props.value || null,
        buildings: buildingsDB.getObjectForTreeSelect(this.props.farm, this.props.showStands)
    }

    getInputNode() {
        return null;
    }

    getValue() {
        const {value} = this.state;
        const {column: {key}} = this.props;
        return {[key]: value};
    }

    onChange = value => {
        this.setState({
            value
        })
    }

    render() {
        const {buildings, value} = this.state;
        return (
            <TreeSelect options={buildings} onChange={this.onChange} value={value}/>
        );
    }
}

LocationEditor.propTypes = {
    farm: PropTypes.string.isRequired,
    showStands: PropTypes.bool,
}

LocationEditor.defaultProps = {
    showStands: true
}

export default LocationEditor;