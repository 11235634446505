import React from "react";
import PropTypes from "prop-types";
import "./_icon-component.scss";
import _ from "lodash";

export default class IconComponent extends React.Component {

    constructor(props) {
        super(props);
        this.checkForFunc = this.checkForFunc.bind(this);
    }

    checkForFunc(item) {
        if (_.isFunction(item)) {
            return item(this.props);
        } else {
            return item
        }
    }

    render() {
        const {className, onClick, disabled} = this.props;
        return (
            <i className={`${this.checkForFunc(className)} icon-component ${this.checkForFunc(disabled) ? 'disabled' : ''}`}
               onClick={() => !this.checkForFunc(disabled) && onClick(this.props)}/>
        );
    }

}

IconComponent.propTypes = {
    className: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

IconComponent.defaultProps = {
    onClick: () => {
    }
};
