import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {get, isFunction, isNil, isString} from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./_mobile-grid-row.scss"
import moment from "moment";
import Radio from "../../radio/Radio";
import {myID} from "../../../../libs/generateID";
import Checkbox from "../../checkbox/Checkbox";

/**
 * Używamy gdy jesteśmy leniwymi tucznikami i nie chce nam się pisac na nowo kompoenentów pod mobilkę dla każdej tabeli
 * Działa w miare uniwersalnie wystarczy dodać mobileRow={<DefaultMobileRow/>} oraz do headerów można dodać _mobileHeader jeśli chcemy wyróznić jeden header i _mobileDate jeśli ma być to data po prawej stronie absolutnie
 * Może słabo działać gdy mamy skompikowane TableGridy ale dla prostych się nada
 * */
class DefaultMobileRow extends Component {

    getContent = (header = {}) => {
        const {component, field, valueFormatter} = header;
        const {index, page, paginationItems, object} = this.props;
        let value = object;
        if (field) {
            value = get(object, field, "");
        }
        if (valueFormatter) {
            value = valueFormatter(value);
        }
        if (!component) {
            return value;
        }
        if (isFunction(component)) {
            return component({index, value, page, paginationItems}, this.props);
        } else {
            return React.cloneElement(component, {index, value, page, paginationItems})
        }
    };

    itemRenderer = (header = {}) => {
        const value = this.getContent(header);
        if (isNil(value)) return null;
        const name = (header.name || "").trim();
        return (
            <Row>
                <Col className={"col-md-auto opacity-75 pr-md-1"} sm={12}>
                    {!!name ? <>{name}:</> : null}
                </Col>
                <Col className={"col-md-auto pl-md-1"} sm={12}>
                    {value}
                </Col>
            </Row>
        )
    }

    getDate = () => {
        const {headers} = this.props;
        const _header = headers.find(h => h._mobileDate);
        if (!_header) return null;
        const header = {..._header, valueFormatter: (value) => moment(value).format("DD.MM.YY HH:mm")}
        const date = this.getContent(header);
        return <div className={"fetura-grid-mobile-row-date"}>
            {date}
        </div>

    }

    getFloatingItems = () => {
        const {headers} = this.props;
        const _headers = headers.filter(h => h._floating);
        if (!_headers.length) return null;
        const items = _headers.map(header => this.getContent(header)).filter(o => !!o);
        if (!items.length) return null;
        return <div className={"fetura-grid-mobile-row-floating"}>
            {items}
        </div>
    }

    getHeader = () => {
        const {headers} = this.props;
        const header = headers.find(h => h._mobileHeader);
        if (!header) return null;
        const text = this.getContent(header);
        return <Row>
            <Col xs={12}>
                <strong>
                    {text}
                </strong>
            </Col>
        </Row>
    }

    onSelect = (e) => {
        const {object, index, isSelectable, onSelect} = this.props;
        e.preventDefault();
        if(!isSelectable) return null;
        onSelect(object, index - 1)
    }

    getSelect = () => {
        const {isSelectable, singleRowSelect, selected} = this.props;
        if (!isSelectable) return null;
        return <Row className={"fetura-grid-mobile-row-select"}>
            <Col xs={12}>
                {
                    singleRowSelect ?
                        <Radio label="" id={myID()} checked={selected} readOnly/>
                        :
                        <Checkbox label="" id={myID()} checked={selected} readOnly/>
                }
            </Col>
        </Row>
    }

    getClassName = () => {
        const {className, object} = this.props;
        const classNameString = isString(className) ? className : isFunction(className) ? className(object) : "";
        return ["fetura-grid-mobile-row", classNameString].filter(o => !!o).join(" ");
    }

    render() {
        const {headers} = this.props;
        const className = this.getClassName();
        return (
            <div className={className} onClick={this.onSelect}>
                {this.getDate()}
                {this.getSelect()}
                {this.getHeader()}
                {
                    headers.filter(h => !h._mobileDate && !h._mobileHeader && !h._mobileHide && !h._floating).map(header => this.itemRenderer(header))
                }
                {this.getFloatingItems()}
            </div>
        );
    }
}

DefaultMobileRow.propTypes = {
    object: PropTypes.object,
    headers: PropTypes.array
};

export default DefaultMobileRow;
