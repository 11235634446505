import React from "react";
import "./_stepper.scss"
import PropTypes from "prop-types"
import {isMobile} from "../../../utils/MobileUtils";
import StepperMobile from "./StepperMobile";

export default class Stepper extends React.Component {

    onClickStep = (index) => {
        this.props.onClickStep(index);
    };

    getChildren() {
        let children = [];
        let ch;
        if (!Array.isArray(this.props.children)) {
            ch = [this.props.children];
        } else {
            ch = this.props.children;
        }
        for (let child of ch) {
            if (child) {
                if (Array.isArray(child)) {
                    children = [...children, ...child];
                } else if (child.props.children) {
                    children = [...children, ...child.props.children];
                } else {
                    children.push(child);
                }
            }
        }
        return children.filter(Boolean);
    }

    render() {
        const {activeStep} = this.props;
        const mobile = isMobile();
        let children = this.getChildren();
        return (
            React.createElement(mobile ? StepperMobile : "div" , {
                children: children.map((child, i) => React.cloneElement(child, {
                    key: i,
                    index: i + 1,
                    mobile: mobile,
                    showLine: i + 1 !== children.length,
                    isActive: activeStep === i + 1,
                    isFinished: activeStep > i + 1,
                    ...child.props,
                    onClick: this.onClickStep
                })),
                className: "stepper",
                activeStep: activeStep // rusz to jeszcze raz Paweł R. to spotkamy się za garażami
            })
        );
    }

}

Stepper.propTypes = {
    activeStep: PropTypes.number,
    onClickStep: PropTypes.func
};

Stepper.defaultProps = {
    onClickStep: () => {
    }
};
