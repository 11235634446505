import React from "react";
import PropTypes from "prop-types"
import MultiValuesComponent from "../../../views/event-chooser-view/events/MultiValuesComponent.js"
import _ from "lodash"

export default class TableHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: this.props.show
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!_.isEqual(this.props, nextProps)) return true;
        return !_.isEqual(this.state, nextState);
    }


    onClick = () => {
        const {onSortChange, header, isSortable} = this.props;
        if (isSortable && !header.notSortable) {
            onSortChange(header);
        }
    };

    getClassName() {
        let className = "header-item";
        let {header: {headerClassName, colWidth, disabledSort, notSortable}, isSortable} = this.props;
        if (isSortable && !notSortable) className += " sortable";
        if (disabledSort) className += " disabled-sort";
        if (headerClassName) className += " " + headerClassName;
        if (colWidth) className += ` col-width-${colWidth}`;
        return className;
    }

    render() {
        const {header: {name, headerComponent, disabledSort, multiValues, animals, field, unit,type}, isSortable, sortColumn, sortType, show} = this.props;

        return (
            <div className={this.getClassName()} onClick={!disabledSort ? this.onClick : null}>
                {
                    headerComponent &&
                    React.cloneElement(headerComponent, {name: name}, headerComponent.props.children)
                }
                {
                    !headerComponent &&
                    <div className="multiValues">
                        {name}
                        {show &&
                        <div>
                            <br/>
                            {multiValues &&
                            <MultiValuesComponent field={field} unit={unit} type={type} animals={animals}/>
                            }
                        </div>
                        }
                    </div>

                }

                {
                    isSortable && sortColumn === name && sortType !== "off" &&
                    <div className="header-sorting-icon">
                        {
                            sortType === "asc" &&
                            <i className="fas fa-sort-amount-up"/>
                        }
                        {
                            sortType === "desc" &&
                            <i className="fas fa-sort-amount-down"/>
                        }
                    </div>
                }
            </div>
        );
    }

}

TableHeader.propTypes = {
    header: PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        headerComponent: PropTypes.element,
        headerClassName: PropTypes.string,
        notSortable: PropTypes.bool,
    }).isRequired,
    isSortable: PropTypes.bool,
    onSortChange: PropTypes.func,
    sortColumn: PropTypes.string,
    sortType: PropTypes.string
};

TableHeader.defaultProps = {
    isSortable: false,
    onHeaderClick: () => {
    }
};
