import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Input from "../../input/Input";
import moment from "moment";
import {isNil} from "lodash";
import {myID} from "../../../../libs/generateID";

class InputEditor extends Component {

    state = {
        value: this.getInitialValue()
    }

    id = myID();
    container = React.createRef();

    componentDidMount() {
        setTimeout(() => {
            try {
                let input = this.container.current.querySelector(".fetura-input").querySelector("input");
                input.focus();
            } catch (e) {
                console.error(e);
            }
        })
    }

    getInitialValue() {
        const {type, value} = this.props;
        if (type === "date" || type === "datetime-local") {
            if (value === "") return null; // jezeli wartosc byla usunieta
            if (value === undefined) return moment(); // jezeli nie bylo wczesniej wartosci
            if (value) {
                if (!(value instanceof moment)) return moment();
                return value;
            } // jezeli byla wartosc
        }
        if (type === "number") {
            let reg = new RegExp('^\\d+$');
            if (reg.test(value)) {
                return +value;
            } else {
                return null;
            }
        }
        return value;
    }

    getInputNode() {
        return null;
    }

    getValue() {
        const {column: {key}} = this.props;
        return {[key]: this.state.value};
    }

    onKeyDown = e => {
        const {type} = this.props;
        if (type === "date" || type === "datetime-local") {
            if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
                let value = this.state.value ? this.state.value.clone() : moment();
                if (e.key === "ArrowUp") {
                    value.add(1, "day");
                } else if (e.key === "ArrowDown") {
                    value.subtract(1, "day");
                }
                this.setState({
                    value
                })
                e.stopPropagation();
                e.preventDefault();
            } else {
                this.props.onOverrideKeyDown(e);
            }
        } else {
            this.props.onOverrideKeyDown(e);
        }
        return false;
    }

    onChange = value => {
        const {type, allowFloat} = this.props;
        if (type === "date" || type === "datetime-local") {
            let date = moment(value);
            this.setState({
                value: date.isValid() ? date : this.state.value
            })
        } else if (type === "number") {
            let val = parseFloat(value);
            if (isNaN(val)) {
                this.setState({
                    value: null
                })
            } else {
                this.setState({
                    value: allowFloat ? val : Math.floor(val)
                })
            }
        } else {
            this.setState({
                value
            })
        }
    };

    getInputValue(value) {
        if (isNil(value) || value === "") return null;
        const {type} = this.props;
        switch (type) {
            case "date":
                return value.format(moment.HTML5_FMT.DATE);
            case "datetime-local":
                return value.format(moment.HTML5_FMT.DATETIME_LOCAL);
            default:
                return value;
        }
    }

    isDisabled() {
        const {disabled} = this.props;
        if (typeof disabled === "function") {
            return disabled(this.props);
        }
        return disabled;
    }

    render() {
        const {type, height, unit} = this.props;
        const {value} = this.state;
        return (
            <div ref={this.container}>
                <Input type={type} onKeyDown={this.onKeyDown} onChange={this.onChange}
                       value={this.getInputValue(value)} style={{height}} disabled={this.isDisabled()} id={this.id}
                       unit={unit}/>
            </div>
        );
    }
}

InputEditor.propTypes = {
    type: PropTypes.string.isRequired,
    disabled: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    allowFloat: PropTypes.bool,
    unit: PropTypes.string,
};

InputEditor.defaultProps = {
    allowFloat: true
}

export default InputEditor;
