import React, {Component} from 'react';
import Tooltip from "../../tooltip/Tooltip";
import "./_tooltip-cell.scss";

class TooltipCell extends Component {
    render() {
        const {value} = this.props;
        return (
            <Tooltip tooltipContent={value}>
                <div className="tooltip-cell">{value}</div>
            </Tooltip>
        );
    }
}

export default TooltipCell;