import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Select from "../../select/Select";

class SelectEditor extends Component {

    state = {
        value: this.getInitialValue()
    }

    getInitialValue() {
        const {value} = this.props;
        if (value === "") return null; // jezeli byla usunieta
        return value;
    }

    getInputNode() {
        return null;
    }

    getValue() {
        const {column: {key}} = this.props;
        return {[key]: this.state.value};
    }

    onChange = value => {
        const {onCommit} = this.props;
        this.setState({
            value
        }, () => {
            onCommit();
        })
    }

    onKeyDown = e => {
        if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
            e.stopPropagation();
        } else {
            this.props.onOverrideKeyDown(e);
        }
    }

    render() {
        const {value, options, ref} = this.props;
        return (
            <div onKeyDown={this.onKeyDown}>
                <Select value={value} options={options} ref={ref} onChange={this.onChange} autofocus
                        insertFirstOnNoSelected={false}/>
            </div>
        );
    }
}

SelectEditor.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default SelectEditor;