import React from "react";
import PropTypes from "prop-types";
import "./_printable-table-grid.scss";
import _ from "lodash";

export default class PrintableTableGrid extends React.Component {

    renderItem(data, header, index) {
        const {field, valueFormatter, component} = header;
        let value = data;
        if (field) {
            value = _.get(data, field, "");
        }
        if (valueFormatter) {
            value = valueFormatter(value);
        }
        if (!component) {
            return value;
        }
        if (_.isFunction(component)) {
            return component({index, value, page: 0, paginationItems: 0}, this.props);
        } else {
            return React.cloneElement(component, {index, value, page: 0, paginationItems: 0})
        }
    }

    render() {
        const {headers, data, shouldIndex} = this.props;
        return (
            <table className="printable-fetura-grid">
                <thead>
                <tr>
                    {
                        shouldIndex && <th>#</th>
                    }
                    {
                        headers.map((header, index) =>
                            <th key={index}
                                className={header.headerClassName && header.headerClassName.includes("empty") ? "empty" : null}>
                                {header.name}
                            </th>
                        )
                    }
                </tr>
                </thead>
                <tbody>
                {
                    data.map((d, i) =>
                        <tr>
                            {
                                shouldIndex && <td>{i + 1}</td>
                            }
                            {
                                headers.map((header, j) =>
                                    <td key={`${i}.${j}`}>
                                        {this.renderItem(d, header, i)}
                                    </td>
                                )
                            }
                        </tr>
                    )
                }
                </tbody>
            </table>
        );
    }

}

PrintableTableGrid.propTypes = {
    headers: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    })).isRequired,
    data: PropTypes.array.isRequired,
    shouldIndex: PropTypes.bool
};