import React, {Component} from 'react';
import "./_filter-level.scss"
import PropTypes from "prop-types"
import Button from "../../../button/Button";
import {withTranslation} from "react-i18next";

class FilterLevel extends Component {

    onAndClick = () => {
        const {value} = this.props;
        let key = Object.keys(value);
        let val = value[key].slice(0);
        val.push({});
        this.props.onChangeFilters(val, this.props.path);
    };

    onOrClick = () => {
        this.props.onOrClick(this.props.path);
    };

    render() {
        const {children, t} = this.props;
        return (
            <div className="filter-level">
                <div className="filter-data">
                    {children}
                </div>
                <div className="filter-level-controls">
                    <Button onClick={this.onAndClick}>{t("filterGrid.and")}</Button>
                    <Button onClick={this.onOrClick}>{t("filterGrid.or")}</Button>
                </div>
            </div>
        );
    }
}

FilterLevel.propTypes = {
    onTypeChange: PropTypes.func.isRequired,
    path: PropTypes.string.isRequired,
    onChangeFilters: PropTypes.func.isRequired,
    value: PropTypes.object.isRequired,
    onOrClick: PropTypes.func.isRequired,
};

export default withTranslation()(FilterLevel);