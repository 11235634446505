import React, {Component} from 'react';
import Select from "../../../select/Select";
import Input from "../../../input/Input";
import Button from "../../../button/Button";
import PropTypes from "prop-types"
import _ from "lodash";
import {FilterTypes} from "../../../../../constans/filter";
import TreeSelect from "../../../tree-select/TreeSelect";
import buildingsDB from "../../../../../database/buildingsDB";
import {withTranslation} from "react-i18next";

class FilterRow extends Component {

    state = {
        headers: this.props.headers.filter(item => item.filterType).map(val => ({
            name: val.name,
            value: val.filterColumn || val.field
        })),
        column: null,
        type: null,
        typeOptions: [],
        inputType: "text",
        inputValue: "",
        filterType: FilterTypes.STRING,
        treeValues: buildingsDB.getObjectForTreeSelect(this.props.farm)
    };

    static getTypes(type, props) {
        const {t} = props;
        switch (type) {
            case FilterTypes.DEV_ADDRESS: {
                return [
                    {
                        name: t("equals"),
                        value: "$devAdrEq"
                    },
                ];
            }
            case FilterTypes.NUMBER:
                return [
                    {
                        name: t("filterGrid.greaterThanOrEquals"),
                        value: "$sanGte"
                    },
                    {
                        name: t("filterGrid.lessThanOrEquals"),
                        value: "$sanLte"
                    },
                    {
                        name: t("equals"),
                        value: "$sanEq"
                    },
                    {
                        name: t("filterGrid.notEquals"),
                        value: "$sanNe"
                    }
                ];
            case FilterTypes.STRING:
                return [
                    {
                        name: t("contains"),
                        value: "$includes"
                    },
                    {
                        name: t("equals"),
                        value: "$eq"
                    },
                    {
                        name: t("filterGrid.startsWith"),
                        value: "$startsWith"
                    },
                    {
                        name: t("endWith"),
                        value: "$endsWith"
                    }
                ];
            case FilterTypes.DATE:
                return [
                    {
                        name: t("equals"),
                        value: "$dateEq"
                    },
                    {
                        name: t("filterGrid.greaterThanOrEquals"),
                        value: "$dateGte"
                    },
                    {
                        name: t("filterGrid.lessThanOrEquals"),
                        value: "$dateLte"
                    }
                ];
            case FilterTypes.LOCATION:
                return [
                    {
                        name: t("filterGrid.below"),
                        value: "$locBelow"
                    },
                    {
                        name: t("filterGrid.onlyLevel"),
                        value: "$locLevel"
                    }
                ];
            default:
                return [];
        }
    }

    static getInputType(type) {
        switch (type) {
            case FilterTypes.NUMBER:
                return "number";
            case FilterTypes.DATE:
                return "date";
            default:
                return "text";
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let keys = Object.keys(nextProps.value);
        let obj = {
            column: keys[0] || null,
            typeOptions: [],
            inputType: "text",
            inputValue: "",
            type: null,
            filterType: FilterTypes.STRING
        };
        if (obj.column) {
            let header = nextProps.headers.find(item => (item.filterColumn || item.field) === obj.column);
            obj.typeOptions = FilterRow.getTypes(header.filterType, nextProps);
            obj.inputType = FilterRow.getInputType(header.filterType);
            obj.filterType = header.filterType;
        }
        if (nextProps.value[keys[0]]) {
            let valueKeys = Object.keys(nextProps.value[keys[0]]);
            obj.type = valueKeys[0];
            obj.inputValue = nextProps.value[keys[0]][valueKeys[0]];
        }
        console.log(obj, nextProps);
        return obj;
    }

    onRemoveClick = () => {
        const {index, path, onRemove} = this.props;
        onRemove(path, index);
    };

    onColumnChange = column => {
        const {path, index, onFilterChange, value} = this.props;
        let pathWithIndex = path + `[${index}]`;
        let keys = Object.keys(value);
        let currentValue = _.get(value, keys[0], null);
        delete value[keys[0]];
        value[column] = currentValue;
        onFilterChange(value, pathWithIndex);
    };

    onTypeChange = type => {
        const {path, index, value, onFilterChange} = this.props;
        let pathWithIndex = path + `[${index}]`;
        let keys = Object.keys(value);
        value[keys[0]] = {[type]: null};
        onFilterChange(value, pathWithIndex);
    };

    onInputChange = val => {
        const {inputType} = this.state;
        const {path, index, value, onFilterChange} = this.props;
        let pathWithIndex = path + `[${index}]`;
        let keys = Object.keys(value);
        let currentValue = value[keys[0]];
        let currentValueKeys = Object.keys(currentValue);
        if (inputType === "number") {
            val = +val;
        }
        currentValue[currentValueKeys[0]] = val;
        onFilterChange(value, pathWithIndex);
    };

    render() {
        const {t} = this.props;
        const {headers, column, type, typeOptions, inputType, inputValue, filterType, treeValues} = this.state;
        return (
            <div className="filter-row">
                <Select value={column} options={headers} onChange={this.onColumnChange}
                        placeholder={t("filterGrid.column")}/>
                <Select value={type} options={typeOptions} onChange={this.onTypeChange} disabled={!column}
                        placeholder={t("filterGrid.condition")}/>
                {
                    filterType === FilterTypes.LOCATION &&
                    <TreeSelect options={treeValues} value={inputValue} onChange={this.onInputChange} disabled={!type}
                                expanded placeholder={t("value")}/>
                }
                {
                    filterType !== FilterTypes.LOCATION &&
                    <Input type={inputType} disabled={!type} onChange={this.onInputChange} value={inputValue}
                           placeholder={t("value")}/>
                }
                <Button icon={<i className="fas fa-trash"/>} onClick={this.onRemoveClick}/>
            </div>
        );
    }
}

FilterRow.propTypes = {
    index: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    onRemove: PropTypes.func.isRequired,
    headers: PropTypes.array.isRequired,
    value: PropTypes.object.isRequired,
    onFilterChange: PropTypes.func.isRequired,
    farm: PropTypes.string.isRequired
};

export default withTranslation()(FilterRow);