import React from "react";
import PropTypes from "prop-types"
import _ from "lodash"
import {Fade} from "react-bootstrap";

export default class TableItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hover: false
        };
        this.itemRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    }

    getValue = () => {
        const {header: {field, valueFormatter}, object, isPinned} = this.props;
        let value = object;
        if (field) {
            value = _.get(object, field, "");
        }
        if (valueFormatter && !isPinned) {
            value = valueFormatter(value);
        }
        return value;
    };

    renderComponent = () => {
        const value = this.getValue();
        const {header: {component}, index, page, paginationItems} = this.props;
        if (!component) {
            return value;
        }
        if (_.isFunction(component)) {
            return component({index, value, page, paginationItems}, this.props);
        } else {
            return React.cloneElement(component, {index, value, page, paginationItems})
        }
    };

    getTitle = () => {
        const {header: {component}} = this.props;
        if (component) return null;
        let value = this.getValue();
        return typeof value === "object" ? null : value;
    };

    getClassName() {
        const {header: {itemClassName, colWidth, rtl}} = this.props;
        const className = ["table-item", itemClassName];
        rtl && (className.push("rtl"));
        colWidth && (className.push(`col-width-${colWidth}`));
        return className.filter(o => o).join(" ");
    }

    handleEnter = (showPortal) => {
        const {hover} = this.state;
        console.log(hover, this.isOverflown(this.itemRef.current));
        if (!hover && showPortal && this.isOverflown(this.itemRef.current)) {
            this.setState({
                hover: true
            })
        }
    };

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    handleLeave = (showPortal, time=2500) => {
        const {hover} = this.state;
        if (hover && showPortal) {
            this.timeout = setTimeout(() => {
                this.setState({
                    hover: false
                })
            }, time)
        }
    };

    /**
     * Pobranie miejsca gdzie znajduje sie tableItem zeby element absolutny pojawil sie nad nim
     * @return {{minHeight: *, top: (number|{enumerable: boolean}), left: (number|{enumerable: boolean}), minWidth: *, position: string, maxWidth: number}}
     */
    getStyle = () => {
        try {
            const obj = this.itemRef.current;
            const maxWidth = window.innerWidth-15-obj.offsetLeft;
            return {
                top: obj.offsetTop,
                left: obj.offsetLeft,
                minWidth: obj.clientWidth,
                minHeight: obj.clientHeight,
                maxWidth: maxWidth,
                position: 'absolute'
            };
        } catch (e) {
            return {
                top: 0,
                left: 0,
                minWidth: 0,
                minHeight: 0,
                maxWidth: 0,
                position: 'absolute'
            }
        }
    };

    /**
     * Sprawdzenie czy tableItem nie jest za duży na miejsce jakie mu przydzielono
     * @param clientWidth
     * @param clientHeight
     * @param scrollWidth
     * @param scrollHeight
     * @return {boolean}
     */
    isOverflown = ({clientWidth, clientHeight, scrollWidth, scrollHeight}) => {
        const {header: {component}} = this.props;
        if (component) return true;
        return scrollHeight > clientHeight || scrollWidth > clientWidth;
    };

    render() {
        const component = this.renderComponent();
        const {header: {overflowInPortal}, mobile} = this.props;
        const title = this.getTitle();
        const className = this.getClassName();
        const {hover} = this.state;
        //div nad dziala tylko na mobilkach na desktopie mamy dzialajacy title
        //                  mobile          czy jest prymitywem             jesli mamy customowy komponent wyswietlany to trzeba przekazac propsa overflowInPortal
        const showPortal = mobile && (_.isString(component) || _.isNumber(component)) || overflowInPortal;
        return (
            <>
                <div ref={this.itemRef}
                     onTouchEnd={() => this.handleLeave(showPortal)}
                     onTouchCancel={() => this.handleLeave(showPortal, 1000)}
                     onTouchMove={() => this.handleLeave(showPortal,0)}
                     onTouchStart={() => this.handleEnter(showPortal)} className={className}
                     title={title}>
                    {component}
                </div>
                <Fade in={hover} unmountOnExit mountOnEnter>
                    <div style={this.getStyle()}
                         className={`${className} item-title`}>
                        {component}
                    </div>
                </Fade>
            </>
        );
    }

}

TableItem.propTypes = {
    header: PropTypes.shape({
        name: PropTypes.node.isRequired,
        field: PropTypes.string,
        valueFormatter: PropTypes.func,
        component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
        itemClassName: PropTypes.string,
        overflowInPortal: PropTypes.bool
    }).isRequired,
    object: PropTypes.oneOfType([PropTypes.node, PropTypes.object]).isRequired,
    index: PropTypes.number,
    page: PropTypes.number,
    paginationItems: PropTypes.number,
    isPinned: PropTypes.bool
};
